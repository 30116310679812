import React from 'react';
import { motion } from 'framer-motion';
import { Instagram } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function TeamPage() {
  const team = [
    {
      name: "Adarsh",
      role: "CEO",
      title: "The Visionary",
      instagram: "adarsh_instagram",
      description: "Meet Adarsh Aryamaan, our fearless leader, a guy with big dreams and even bigger hair (metaphorically, of course). When he's not busy surfing the cosmic waves of creativity, you'll find him crafting plans that could rival any plot twist in a retro sci-fi flick. He's the mastermind steering our spaceship, leading us toward success with a cool wink and a pair of imaginary aviator sunglasses.",
      gradient: "from-blue-500/10 to-purple-500/10"
    },
    {
      name: "Rishabh",
      role: "Marketing Head",
      title: "The Charisma Connoisseur",
      instagram: "rishabh_instagram",
      description: "Rishabh \"The Flash\", our marketing wizard, is the guy who could sell ice to a polar bear—while doing the moonwalk. He's got the charm of a game show host, and with his snappy ideas, he can make any campaign feel like it's fresh out of a neon-lit arcade. Rishabh makes sure our brand shines brighter than a disco ball on Saturday night.",
      gradient: "from-purple-500/10 to-pink-500/10"
    },
    {
      name: "Utkarsh",
      role: "Technical Director",
      title: "The Code Cowboy",
      instagram: "utkarsh_instagram",
      description: "Utkarsh \"Byte King\", our tech genius, is the one behind all those mind-blowing algorithms. If you need something fixed, coded, or debugged, Utkarsh got you covered faster than a cassette rewinds. He's the ultimate mix of brains and geeky cool, rocking that old-school coding magic with a floppy disk in one hand and a soldering iron in the other.",
      gradient: "from-pink-500/10 to-blue-500/10"
    }
  ];

  return (
    <div className="min-h-screen bg-[#000000] overflow-hidden font-['Syne'] px-4 sm:px-6 lg:px-8">
      {/* Background elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-pink-500/5 animate-gradient-x opacity-30" />
        <div 
          className="absolute inset-0 opacity-[0.03]"
          style={{
            backgroundImage: `radial-gradient(circle at center, white 0.5px, transparent 0.5px)`,
            backgroundSize: '24px 24px'
          }}
        />
      </div>

      <div className="container mx-auto relative z-10 pt-16 sm:pt-24 pb-8 sm:pb-24">
        {/* Header */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12 sm:mb-20"
        >
          <h1 className="font-['ClashDisplay'] text-4xl sm:text-6xl font-light text-white tracking-tighter mb-4 sm:mb-6">
            Meet Our Team
          </h1>
          <p className="text-gray-400 text-lg sm:text-xl font-light tracking-wider max-w-2xl mx-auto px-4">
            The creative minds behind FloNova's innovation and success.
          </p>
        </motion.div>

        {/* Team Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 max-w-7xl mx-auto">
          {team.map((member, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`bg-gradient-to-br ${member.gradient} backdrop-blur-sm rounded-2xl p-6 sm:p-8 border border-gray-800/30`}
            >
              <div className="text-center mb-4 sm:mb-6">
                <h2 className="font-['ClashDisplay'] text-2xl sm:text-3xl font-light text-white tracking-tighter">
                  {member.name}
                </h2>
                <p className="text-blue-400 text-xs sm:text-sm tracking-wider mt-1">
                  {member.role}
                </p>
                <p className="text-gray-400 text-base sm:text-lg font-light italic mt-2">
                  {member.title}
                </p>
              </div>
              
              <p className="text-gray-400 text-xs sm:text-sm tracking-wider mb-6 sm:mb-8 leading-relaxed">
                {member.description}
              </p>

              <a
                href={`https://instagram.com/${member.instagram}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center gap-2 text-white text-xs sm:text-sm hover:text-blue-400 transition-colors"
              >
                <Instagram className="w-4 h-4" />
                <span>Follow on Instagram</span>
              </a>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
} 