import { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { ChevronDown, Menu, X, Sun, Moon, BarChart, Users, Zap, Shield, Award, Clock, Rocket, Target, Layers } from 'lucide-react'
import xjs from './X.js'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faChartLine, faBox, faGlobe, faFileAlt, faDollarSign, faUsers, faPercent, faLayerGroup, faSun, faMoon, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import PowerfulFeatures from './components/powerful features.tsx';
import { 
  Brain as BrainIcon, 
  Users as UsersIcon, 
  BarChart as BarChartIcon, 
  Rocket as RocketIcon, 
  MessageSquare as MessageSquareIcon, 
  Target as TargetIcon, 
  Activity as ActivityIcon, 
  Settings as SettingsIcon 
} from "lucide-react"
import { Card, CardContent, CardHeader, CardTitle } from "./components/ui/card"
import { cn } from "./lib/utils"
import { LineChart, Line, AreaChart, Area, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/ui/tabs"
import { Badge } from "./components/ui/badge"
import { Button } from "./components/ui/button"
import { ArrowRight, Globe, Code, Brain } from 'lucide-react'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './globals.css'
import brand1 from './image/brand1.jpg';
import brand2 from './image/brand2.jpg';
import brand3 from './image/brand3.jpg';
import brand4 from './image/brand4.jpg';
import brand5 from './image/brand5.jpg';
import brand6 from './image/brand6.jpg';
import brand7 from './image/brand7.jpg';
import brand8 from './image/brand8.png';
import pic2 from './image/pic2.png';
import merchant from './image/merchant.png';
import graph from './image/graphs.svg';
import text from './image/1.png';
import graph2 from './image/md.jpg';
import analytics from './image/analytics.jpg';
import apis from './image/apis.jpg';
import md from './image/md.jpg';
import stats from './image/stats.jpg';
import trends from './image/trends.jpg';
import campaigns from './image/campaigns.jpg';
import Customers from './image/Customers.jpg';
import recent from './image/recent.jpeg';
import ads from './image/ads.jpg';
import products from './image/products.jpg';
import datas from './image/data.jpg';
import { data } from 'autoprefixer'
import optimization from './image/Optimization.png';
import aid from './image/aid.jpeg';
import perc from './image/perc.jpg';
import techstack from './image/techstack.jpg';
/*
import dataVisualizationImage from './images/md.jpg';
import performanceAnalyticsImage from './images/md.jpg';
import inventoryTrackingImage from './images/md.jpg';
import crossPlatformImage from './images/md.jpg';
import customReportsImage from './images/md.jpg';
import salesTrackingImage from './images/graphs.png';
import customerInsightsImage from './images/md.jpg';
import promotionsManagementImage from './images/md.jpg';
import multiChannelSupportImage from './images/md.jpg';
*/

import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const placeholderImage = "https://via.placeholder.com/300x200.png?text=Feature+Image";

const ImageGallery = () => {
  const [visibleImages, setVisibleImages] = useState([]);
  const galleryRef = useRef(null);

  const images = [
    pic2
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleImages((prev) => [...new Set([...prev, entry.target.dataset.index])]);
          }
        });
      },
      { threshold: 0.1 }
    );

    const galleryItems = galleryRef.current.children;
    for (let item of galleryItems) {
      observer.observe(item);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="mx-auto px-4 py-9">
      <div 
        ref={galleryRef} 
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-5"
      >
        {images.map((src, index) => (
          <motion.div 
            key={index} 
            data-index={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: visibleImages.includes(index.toString()) ? 1 : 0 }}
            transition={{ duration: 2 }}
          >
            <img 
              src={src} width={2000} height={1000}
              alt={`Gallery image ${index + 1}`} 
              className="object-cover rounded-lg"
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

const ImageGallery2 = () => {
  const [visibleImages, setVisibleImages] = useState([]);
  const galleryRef = useRef(null);

  const images = [
    graph2
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleImages((prev) => [...new Set([...prev, entry.target.dataset.index])]);
          }
        });
      },
      { threshold: 0.1 }
    );

    const galleryItems = galleryRef.current.children;
    for (let item of galleryItems) {
      observer.observe(item);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="mx-auto px-1 py-1">
      <div 
        ref={galleryRef} 
        className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-1"
      >
        {images.map((src, index) => (
          <motion.div 
            key={index} 
            data-index={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: visibleImages.includes(index.toString()) ? 1 : 0 }}
            transition={{ duration: 2 }}
          >
            <img 
              src={src} 
              alt={`Gallery image ${index + 1}`} 
              className="w-full h-full object-cover rounded-lg"
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

const ImageGallery3 = () => {
  const [visibleImages, setVisibleImages] = useState([]);
  const galleryRef = useRef(null);

  const images = [
    graph, text
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleImages((prev) => [...new Set([...prev, entry.target.dataset.index])]);
          }
        });
      },
      { threshold: 0.1 }
    );

    const galleryItems = galleryRef.current.children;
    for (let item of galleryItems) {
      observer.observe(item);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="mx-auto px-4 py-9">
      <div 
        ref={galleryRef} 
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-20"
      >
        {images.map((src, index) => (
          <motion.div 
            key={index} 
            data-index={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: visibleImages.includes(index.toString()) ? 1 : 0 }}
            transition={{ duration: 2 }}
          >
            <img 
              src={src} width={700} height={250} 
              alt={`Gallery image ${index + 1}`} 
              className="center object-cover rounded-lg"
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null)

  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  return (
    <section id="faqs" className="py-6 bg-white">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-base font-semibold leading-7 text-blue-400 text-center">
          Support
        </h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl text-center mb-8">
          Frequently Asked Questions
        </p>
        <dl className="space-y-6">
          {faqs.map((faq, index) => (
            <div key={index} className="bg-gray-50 rounded-lg shadow-sm">
              <dt>
                <button
                  onClick={() => toggleQuestion(index)}
                  className="flex justify-between items-center w-full text-left px-4 py-5 sm:p-6 focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-900"
                  aria-expanded={openIndex === index}
                >
                  <span className="text-lg font-semibold font-montserrat">{faq.question}</span>
                  <ChevronDown
                    className={`w-5 h-5 text-blue-500 transition-transform duration-300 ease-in-out ${
                      openIndex === index ? 'transform rotate-180' : ''
                    }`}
                  />
                </button>
              </dt>
              <AnimatePresence initial={false}>
                {openIndex === index && (
                  <motion.dd
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ 
                      height: "auto", 
                      opacity: 1,
                      transition: {
                        height: {
                          duration: 0.3,
                          ease: "easeOut"
                        },
                        opacity: {
                          duration: 0.2,
                          delay: 0.1
                        }
                      }
                    }}
                    exit={{ 
                      height: 0, 
                      opacity: 0,
                      transition: {
                        height: {
                          duration: 0.3,
                          ease: "easeIn"
                        },
                        opacity: {
                          duration: 0.2
                        }
                      }
                    }}
                    className="overflow-hidden"
                  >
                    <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                      <p className="text-base text-gray-600 font-roboto">{faq.answer}</p>
                    </div>
                  </motion.dd>
                )}
              </AnimatePresence>
            </div>
          ))}
        </dl>
      </div>
    </section>
  )
}

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  whileInView: { opacity: 1, y: 0 },
  transition: { duration: 0.5 }
}

const staggerContainer = {
  initial: {},
  whileInView: { transition: { staggerChildren: 0.1 } }
}

const features = [
  {
    title: "Real-Time Data Visualizations",
    description: "Instantly grasp key performance indicators with visually appealing charts and graphs.",
    icon: <FontAwesomeIcon icon={faChartLine} className="w-12 h-12 text-blue-500 -mt-7" />,
    benefit: "Make informed, data-driven decisions with easy-to-understand visual representations of your business data.",
    image: md 
  },
  {
    title: "AI-Powered Ad Image Generation",
    description: "Generate engaging ad images and creatives using advanced AI technology.",
    icon: <FontAwesomeIcon icon={faFileAlt} className="w-12 h-12 text-blue-500 -mt-7" />,
    benefit: "Save time and resources by automatically creating professional ad visuals tailored to your brand.",
    image: ads
  },
  {
    title: "Inventory Tracking and Management",
    description: "Real-time inventory management with low-stock alerts and reorder reminders.",
    icon: <FontAwesomeIcon icon={faBox} className="w-12 h-12 text-blue-500 -mt-7" />,
    benefit: "Prevent stockouts and ensure you're always ready to meet customer demand.",
    image: recent
  },
  {
    title: "Sales and Revenue Tracking",
    description: "Track sales performance, revenue growth, and customer purchase behaviors in real-time.",
    icon: <FontAwesomeIcon icon={faDollarSign} className="w-11 h-11 text-blue-500 -mt-7" />,
    benefit: "Focus on your best-performing products and tailor promotions accordingly.",
    image: stats
  },
  {
    title: "Customer Insights and Management",
    description: "Manage customer profiles and generate insights into customer segments.",
    icon: <FontAwesomeIcon icon={faUsers} className="w-12 h-12 text-blue-500 -mt-7" />,
    benefit: "Tailor marketing campaigns to specific customer segments, improving retention and lifetime value.",
    image: campaigns
  },
  {
    title: "Social Media Trend Analytics",
    description: "Stay on top of social media trends and engagement metrics in real-time.",
    icon: <FontAwesomeIcon icon={faLayerGroup} className="w-12 h-12 text-blue-500 -mt-6" />,
    benefit: "Make data-driven decisions by tracking social media performance, trending topics, and customer engagement across platforms.",
    image: trends
  }
]

const metrics = [
  {
    value: "20+",
    label: "Apps launched",
    shape: (
      <svg className="absolute inset-0 text-purple-500/10" viewBox="0 0 100 100">
        <path d="M50 25L75 50L50 75L25 50Z" fill="currentColor" />
      </svg>
    ),
  },
  {
    value: "100%",
    label: "Project delivered ontime",
  },
  {
    value: "93%",
    label: "Customer retention",
  },
  {
    value: "100%",
    label: "Success rate",
    highlight: true,
  },
  {
    value: "98%",
    label: "Customer satisfaction",
  },
  {
    value: "20+",
    label: "Products launched",
    shape: (
      <svg className="absolute inset-0 text-lime-500/10" viewBox="0 0 100 100">
        <path d="M50 25L75 50L50 75L25 50Z" fill="currentColor" />
      </svg>
    ),
  },
]

const workflowSteps = [
  {
    icon: UsersIcon,
    title: "User Input & Segmentation",
    description: "Defining campaign goals and target audience always comes first. AI analyzes audience data to create precise segments.",
    color: "bg-blue-500/10 text-blue-500",
    delay: 0
  },
  {
    icon: TargetIcon,
    title: "Ad Creation & Targeting",
    description: "With human guidance, AI tools generate ad copy and designs tailored to the audience.",
    color: "bg-purple-500/10 text-purple-500",
    delay: 0.1
  },
  {
    icon: RocketIcon,
    title: "Campaign Launch & Management",
    description: "Automated campaign deployment with real-time monitoring and adjustments.",
    color: "bg-pink-500/10 text-pink-500",
    delay: 0.2
  },
  {
    icon: ActivityIcon,
    title: "Performance Monitoring",
    description: "Continuous analysis of campaign metrics and ROI optimization.",
    color: "bg-emerald-500/10 text-emerald-500",
    delay: 0.3
  }
]

const touchpoints = [
  { icon: MessageSquareIcon, position: "top-[20%] left-[25%]", delay: 0.7 },
  { icon: BarChartIcon, position: "top-1/3 right-1/4", delay: 0.9 },
  { icon: SettingsIcon, position: "bottom-1/3 left-1/3", delay: 0.9 },
  { icon: BrainIcon, position: "bottom-[25%] right-[25%]", delay: 0.6 }
]

const faqs = [
  {
    question: "What is automated marketing?",
    answer: "Automated marketing uses software to automate repetitive marketing tasks such as email campaigns, social media posting, and ad management. It helps businesses save time, improve efficiency, and deliver personalized experiences to customers at scale."
  },
  {
    question: "How can automated marketing benefit my business?",
    answer: "Automated marketing can significantly benefit your business by increasing efficiency, improving lead nurturing, enhancing customer engagement, providing better data insights, and ultimately driving more conversions and revenue."
  },
  {
    question: "Is it difficult to set up automated marketing?",
    answer: "With our platform, setting up automated marketing is straightforward. We provide user-friendly interfaces, templates, and step-by-step guides to help you get started quickly. Our support team is also available to assist you throughout the process."
  },
  {
    question: "Can I personalize my automated marketing campaigns?",
    answer: "Our platform allows for deep personalization of your marketing campaigns. You can segment your audience, use dynamic content, and leverage customer data to create highly targeted and personalized messages across various channels."
  },
  {
    question: "How do I measure the success of my automated marketing efforts?",
    answer: "Our platform provides comprehensive analytics and reporting tools. You can track key metrics such as open rates, click-through rates, conversion rates, ROI, and more. These insights help you understand campaign performance and make data-driven decisions to optimize your marketing efforts."
  }
]

const analyticsData = [
  { name: 'Jan', value: 400 },
  { name: 'Feb', value: 300 },
  { name: 'Mar', value: 600 },
  { name: 'Apr', value: 800 },
  { name: 'May', value: 500 },
  { name: 'Jun', value: 700 },
]

const platformData = [
  { name: 'Facebook', value: 400 },
  { name: 'Instagram', value: 300 },
  { name: 'Twitter', value: 300 },
  { name: 'LinkedIn', value: 200 },
]

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

export default function LandingPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [scrollY, setScrollY] = useState(0)
  const [activeDropdown, setActiveDropdown] = useState(null)

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const metricsSection = document.querySelector('.metrics-section');
      const metricsPosition = metricsSection?.getBoundingClientRect();
      
      // Check if we're in the hero or metrics section
      const isInHeroOrMetrics = scrollY <= 50 || 
        (metricsPosition && 
         metricsPosition.top <= 0 && 
         metricsPosition.bottom >= 0);
      
      setScrollY(isInHeroOrMetrics ? 0 : scrollY);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  }

  const brandLogos = [
    brand1, brand2, brand3, brand4,
    brand5, brand6, brand7, brand8,
    brand1, brand2, brand3, brand4,
    brand5, brand6, brand7, brand8,
  ];

    const aboutSlides = [
    {
      title: "Our Mission",
      content: "At FloNova, our mission is to empower businesses with cutting-edge AI-driven marketing solutions. We strive to simplify complex marketing processes and drive exceptional results for our clients."
    },
    {
      title: "Innovation at Core",
      content: "We're constantly pushing the boundaries of what's possible in digital marketing. Our team of experts is dedicated to developing innovative features that keep our clients ahead of the curve."
    },
    {
      title: "Customer Success",
      content: "Your success is our success. We provide unparalleled support and guidance to ensure you get the most out of our platform. Our customer success team is always ready to assist you in achieving your marketing goals."
    },
    {
      title: "Data-Driven Approach",
      content: "We believe in the power of data. FloNova's analytics tools provide deep insights into your marketing performance, allowing you to make informed decisions and optimize your strategies for maximum impact."
    }
  ]

  const navItems = [
    {
      title: "Services",
      dropdown: [
        { title: "AI-Powered Analytics", href: "#ai-analytics" },
        { title: "Cross-Platform Campaigns", href: "#cross-platform" },
        { title: "Business features", href: "#technology" },
      ]
    },
    {
      title: "About Us",
      dropdown: [
        { title: "Team", href: "/team" },
        { title: "Vision & Mission", href: "https://www.zvortex.co/" },
        { title: "Technology", href: "#technology" },
      ]
    },
    {
      title: "Resources",
      dropdown: [
        { title: "API's", href: "/apis" },
        { title: "OpenAI", href: "https://openai.com/chatgpt/overview/" },
        { title: "FAQs", href: "#faqs" },
      ]
    },
    {
      title: "Contact",
      to: "/contact"
    }
  ]

  const fadeInUp = {
    initial: { opacity: 0, y: 50 },
    whileInView: { opacity: 1, y: 0 },
    viewport: { once: true },
    transition: { duration: 0.6, ease: "easeOut" }
  };

  const fadeInLeft = {
    initial: { opacity: 0, x: -100 },
    whileInView: { opacity: 1, x: 0 },
    viewport: { once: true },
    transition: { duration: 0.6, ease: "easeOut" }
  };

  const fadeInRight = {
    initial: { opacity: 0, x: 100 },
    whileInView: { opacity: 1, x: 0 },
    viewport: { once: true },
    transition: { duration: 0.6, ease: "easeOut" }
  };

  const scaleUp = {
    initial: { opacity: 0, scale: 0.8 },
    whileInView: { opacity: 1, scale: 1 },
    viewport: { once: true },
    transition: { duration: 0.6, ease: "easeOut" }
  };

  const staggerContainer = {
    initial: {},
    whileInView: {
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const popUp = {
    initial: { opacity: 0, scale: 0.5 },
    whileInView: { opacity: 1, scale: 1 },
    viewport: { once: true },
    transition: { duration: 0.4, ease: "easeOut" }
  };

  const EnhancedMarketingSections = () => {
    const analyticsData = [
      { name: 'Jan', value: 400 },
      { name: 'Feb', value: 300 },
      { name: 'Mar', value: 600 },
      { name: 'Apr', value: 800 },
      { name: 'May', value: 500 },
      { name: 'Jun', value: 700 },
    ]

    const platformData = [
      { name: 'Facebook', value: 400 },
      { name: 'Instagram', value: 300 },
      { name: 'Twitter', value: 300 },
      { name: 'LinkedIn', value: 200 },
    ]

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

    return (
      <section id="why-choose-FloNova" className="py-8 sm:py-12 bg-gradient-to-b from-gray-50 to-white">
        <div className="container mx-auto px-4">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-3xl md:text-4xl font-bold mb-12 text-center font-montserrat"
          >
            Why Choose FloNova?
          </motion.h2>
          <div className="grid md:grid-cols-3 gap-6 md:gap-8">
            {[
              { icon: <BarChart className="w-12 h-12 mb-4 text-blue-500" />, title: "Data-Driven Insights", description: "Leverage advanced analytics to make informed decisions and optimize your campaigns in real-time." },
              { icon: <Users className="w-12 h-12 mb-4 text-blue-500" />, title: "Audience Segmentation", description: "Target the right customers with precision using our AI-powered segmentation tools." },
              { icon: <Zap className="w-12 h-12 mb-4 text-blue-500" />, title: "Lightning-Fast Automation", description: "Streamline your workflow with intelligent automation across all marketing channels." },
              { icon: <Shield className="w-12 h-12 mb-4 text-blue-500" />, title: "Enterprise-Grade Security", description: "Rest easy knowing your data is protected with state-of-the-art security measures." },
              { icon: <Award className="w-12 h-12 mb-4 text-blue-500" />, title: "Innovative Platform", description: "Stay ahead of the curve with our cutting-edge marketing technology." },
              { icon: <Clock className="w-12 h-12 mb-4 text-blue-500" />, title: "24/7 Expert Support", description: "Our dedicated team is always available to ensure your success with FloNova." },
            ].map((feature, index) => (
              <motion.div
                key={index}
                variants={popUp}
                whileHover={{ scale: 1.05 }}
                className="relative overflow-hidden rounded-2xl bg-white p-8 shadow-lg hover:shadow-xl transition-all"
              >
                <div className="relative">
                  {feature.icon}
                  <h3 className="text-xl font-semibold leading-6 text-gray-900 mb-2">{feature.title}</h3>
                  <p className="text-sm leading-6 text-gray-600">{feature.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    )
  }

  const ContactSection = () => {
    return (
      <section className="relative min-h-screen bg-black overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-pink-500/5 animate-gradient-x" />
          <div 
            className="absolute inset-0 opacity-5"
            style={{
              backgroundImage: `radial-gradient(circle at center, white 0.5px, transparent 0.5px)`,
              backgroundSize: '24px 24px'
            }}
          />
        </div>

        <div className="container mx-auto px-4 py-16 relative z-10">
          <div className="max-w-7xl mx-auto">
            {/* Header */}
            <div className="text-center mb-16">
              <motion.h1 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-6xl font-bold text-white mb-4"
              >
                Get in Touch
                <span className="block text-5xl">with Us</span>
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="text-gray-400 text-xl"
              >
                The more we know, the better we can help.
              </motion.p>
            </div>

            {/* Contact Form */}
            <div className="grid lg:grid-cols-2 gap-16 items-center">
              {/* Form Section */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4 }}
                className="space-y-8"
              >
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <input
                      type="text"
                      placeholder="YOUR NAME HERE"
                      className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      placeholder="EMAIL"
                      className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                    />
                  </div>
                  <div>
                    <input
                      type="tel"
                      placeholder="PHONE NUMBER"
                      className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="COMPANY NAME"
                      className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-3 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                    />
                  </div>
                </div>

                {/* Navigation Buttons */}
                <div className="flex justify-between items-center pt-6">
                  <button className="text-white opacity-50 hover:opacity-100 transition-opacity">
                    Prev
                  </button>
                  <div className="text-white">
                    <span className="text-blue-400">2</span> / 3
                  </div>
                  <button className="text-white bg-blue-500 px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors">
                    Next
                  </button>
                </div>
              </motion.div>

              {/* Contact Information */}
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.6 }}
                className="lg:pl-16"
              >
                <h2 className="text-2xl font-semibold text-white mb-8">Contact Information</h2>
                <div className="space-y-6">
                  <p className="text-gray-400">
                    Fill out the form and our team will get back to you within 24 hours.
                  </p>
                  {/* Add any additional contact information here */}
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <div className="min-h-screen bg-white text-gray-900 transition-colors duration-1">
      <motion.header
        className={`fixed w-full z-50 transition-colors duration-300 ${
          scrollY > 50 ? 'bg-white/90 backdrop-blur-md' : 'bg-black'
        }`}
        initial="hidden"
        animate="visible"
        variants={navVariants}
      >
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <a 
              href="#" 
              className={`font-['ClashDisplay'] text-2xl font-bold ${
                scrollY > 50 
                  ? 'text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600' 
                  : 'text-white'
              }`}
            >
              FloNova
            </a>
            
            <nav className="hidden md:flex space-x-8">
              {navItems.map((item, index) => (
                <div key={index} className="relative group"
                     onMouseEnter={() => setActiveDropdown(item.title)}
                     onMouseLeave={() => setActiveDropdown(null)}>
                  {item.title === "Contact" ? (
                    <Link to="/contact" className={`${scrollY > 50 ? 'text-gray-800 hover:text-blue-400' : 'text-white hover:text-blue-300'} transition-colors`}>
                      {item.title}
                    </Link>
                  ) : (
                    <a href={item.href || '#'} className={`${scrollY > 50 ? 'text-gray-800 hover:text-blue-400' : 'text-white hover:text-blue-300'} transition-colors`}>
                      {item.title}
                    </a>
                  )}
                  {item.dropdown && (
                    <AnimatePresence>
                      {activeDropdown === item.title && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          transition={{ duration: 0.2 }}
                          className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                        >
                          <div className="py-1">
                            {item.dropdown.map((dropdownItem, dropdownIndex) => (
                              dropdownItem.href.startsWith('http') || dropdownItem.href.startsWith('#') ? (
                                <a
                                  key={dropdownIndex}
                                  href={dropdownItem.href}
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                >
                                  {dropdownItem.title}
                                </a>
                              ) : (
                                <Link
                                  key={dropdownIndex}
                                  to={dropdownItem.href}
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                >
                                  {dropdownItem.title}
                                </Link>
                              )
                            ))}
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  )}
                </div>
              ))}
            </nav>
            
            <div className="flex items-center space-x-4">
              <Link 
                to="/demo-request" 
                className={`hidden md:inline-block px-4 py-2 rounded-md ${
                  scrollY > 50 
                    ? 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white' 
                    : 'bg-white text-black hover:bg-gray-100'
                } transition-colors`}
              >
                Request demo
              </Link>
              <button 
                className="md:hidden" 
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <FontAwesomeIcon 
                  icon={isMenuOpen ? faTimes : faBars} 
                  size="lg" 
                  className={scrollY > 50 ? 'text-gray-800' : 'text-white'}
                />
              </button>
            </div>
          </div>
        </div>

        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="md:hidden bg-white py-4"
          >
            <nav className="flex flex-col space-y-4 px-4">
              {navItems.map((item, index) => (
                <div key={index} className="relative">
                  {item.title === "Contact" ? (
                    <Link
                      to="/contact"
                      className="block w-full text-left text-gray-800 hover:text-blue-400 transition-colors py-2"
                    >
                      {item.title}
                    </Link>
                  ) : (
                    <button
                      onClick={() => setActiveDropdown(activeDropdown === item.title ? null : item.title)}
                      className="w-full text-left text-gray-800 hover:text-blue-400 transition-colors py-2"
                    >
                      {item.title}
                    </button>
                  )}
                  {item.dropdown && (
                    <AnimatePresence>
                      {activeDropdown === item.title && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.2 }}
                          className="mt-2 bg-white"
                        >
                          <div className="py-2">
                            {item.dropdown.map((dropdownItem, dropdownIndex) => (
                              dropdownItem.href.startsWith('http') || dropdownItem.href.startsWith('#') ? (
                                <a
                                  key={dropdownIndex}
                                  href={dropdownItem.href}
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                >
                                  {dropdownItem.title}
                                </a>
                              ) : (
                                <Link
                                  key={dropdownIndex}
                                  to={dropdownItem.href}
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                >
                                  {dropdownItem.title}
                                </Link>
                              )
                            ))}
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  )}
                </div>
              ))}
              <Link to="/contact" className="px-4 py-2 rounded-md bg-gradient-to-r from-blue-500 to-blue-500 hover:from-l-600 hover:to-blue-600 transition-colors text-white text-center">
                Request Demo
              </Link>
            </nav>
          </motion.div>
        )}
      </motion.header>

      <main>
        <section id="hero" className="relative pt-16 md:pt-24 pb-16 px-4 bg-[#000000] min-h-screen flex items-center justify-center overflow-hidden">
          {/* Animated background gradient - make it more subtle */}
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-black-500/5 via-purple-500/5 to-pink-500/5 animate-gradient-x" />
            {/* Make dots more subtle */}
            <div 
              className="absolute inset-0 opacity-5"
              style={{
                backgroundImage: `radial-gradient(circle at center, white 0.5px, transparent 0.5px)`,
                backgroundSize: '24px 24px'
              }}
            />
          </div>

          {/* Make particles more subtle */}
          <div className="absolute inset-0">
            {[...Array(20)].map((_, i) => (
              <motion.div
                key={i}
                className="absolute w-1 h-1 bg-blue-400/20 rounded-full"
                initial={{ 
                  x: Math.random() * window.innerWidth, 
                  y: Math.random() * window.innerHeight 
                }}
                animate={{ 
                  x: Math.random() * window.innerWidth, 
                  y: Math.random() * window.innerHeight,
                  scale: [1, 1.5, 1],
                  opacity: [0.2, 0.5, 0.2]
                }}
                transition={{ 
                  duration: 10 + Math.random() * 10,
                  repeat: Infinity,
                  ease: "linear"
                }}
              />
            ))}
          </div>

          {/* Make gradient orbs more subtle */}
          <div className="absolute bottom-0 left-0 w-[500px] h-[500px] bg-gradient-radial from-blue-500/10 via-transparent to-transparent blur-2xl" />
          <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-gradient-radial from-purple-500/10 via-transparent to-transparent blur-2xl" />

          <div className="container mx-auto relative z-10">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              {/* Left side - Text content */}
              <motion.div 
                className="text-left px-4 md:px-0"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <div className="flex-1 relative"> {/* Added relative positioning */}
                  <motion.h1 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="font-['ClashDisplay'] text-6xl font-light text-white tracking-tighter leading-[0.9]"
                  >
                    FloNova
                  </motion.h1>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.1 }}
                    className="mt-4"
                  >
                    <h2 className="font-['ClashDisplay'] text-3xl font-light tracking-tighter leading-tight">
                      <span className="text-white">AUTOMATING ADVERTISING</span>
                      <span className="block text-2xl mt-1 bg-gradient-to-r from-white via-gray-200 to-white bg-clip-text text-transparent">
                        USING AI
                      </span>
                    </h2>
                  </motion.div>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                    className="text-gray-500 text-base font-light tracking-wider mt-4 mb-12" // Removed absolute positioning, added margin
                  >
                    Transform your marketing strategy with intelligent automation powered by cutting-edge AI technology.
                  </motion.p>

                  {/* Buttons and social icons */}
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 1 }}
                    className="flex flex-col space-y-6"
                  >
                    <Link 
                      to="/demo-request" 
                      className="group relative px-8 py-3 rounded-md bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:from-blue-600 hover:to-blue-700 transition-all duration-300 w-fit"
                    >
                      <span className="relative z-10">Learn More</span>
                      <motion.div
                        className="absolute inset-0 rounded-md bg-white/20"
                        initial={false}
                        whileHover={{ scale: 1.05 }}
                        transition={{ duration: 0.2 }}
                      />
                    </Link>

                    {/* Social Media Icons */}
                    <motion.div 
                      className="flex space-x-6"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 1.2 }}
                    >
                      <a 
                        href="#" 
                        className="text-gray-400 hover:text-white transition-colors"
                        aria-label="Facebook"
                      >
                        <FontAwesomeIcon icon={faFacebook} className="w-6 h-6" />
                      </a>
                      <a 
                        href="#" 
                        className="text-gray-400 hover:text-white transition-colors"
                        aria-label="Twitter"
                      >
                        <FontAwesomeIcon icon={faTwitter} className="w-6 h-6" />
                      </a>
                      <a 
                        href="#" 
                        className="text-gray-400 hover:text-white transition-colors"
                        aria-label="Instagram"
                      >
                        <FontAwesomeIcon icon={faInstagram} className="w-6 h-6" />
                      </a>
                    </motion.div>
                  </motion.div>
                </div>
              </motion.div>

              {/* Right side - Animated illustration */}
              <motion.div 
                className="relative mt-24 md:mt-36"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1, delay: 0.4 }}
              >
                <div className="relative w-full aspect-square max-w-[500px] mx-auto">
                  {/* Keep circles in original position */}
                  <motion.div
                    className="absolute inset-0"
                    animate={{ 
                      rotate: 360,
                      scale: [1, 1.1, 1],
                    }}
                    transition={{ 
                      duration: 20, 
                      repeat: Infinity,
                      ease: "linear" 
                    }}
                  >
                    <div className="absolute inset-0 rounded-full border-2 border-blue-500/20" />
                    <div className="absolute inset-4 rounded-full border-2 border-purple-500/20" />
                    <div className="absolute inset-8 rounded-full border-2 border-pink-500/20" />
                  </motion.div>

                  {/* Move only the image down */}
                  <motion.div 
                    className="relative z-10 bg-gradient-to-br from-blue-500/10 to-purple-500/10 rounded-2xl p-4 translate-y-20"
                    transition={{ duration: 0.3 }}
                  >
                    {/* Floating card */}
                    <motion.div
                      className="absolute -right-6 -top-6 z-20 bg-white/10 backdrop-blur-lg rounded-xl p-4 border border-white/20 shadow-xl"
                      initial={{ scale: 0.8, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ delay: 1 }}
                    >
                      <div className="text-white">
                        <p className="font-['ClashDisplay'] text-sm font-light tracking-wider">Boost your</p>
                        <p className="font-['ClashDisplay'] text-xl font-light tracking-tighter">
                          Marketing ROI
                        </p>
                      </div>
                    </motion.div>

                    {/* Add hover effect to just the image */}
                    <motion.div
                      whileHover={{ scale: 1.02 }}
                      transition={{ duration: 0.3 }}
                    >
                      <img
                        src={text}
                        alt="AI Marketing Visualization"
                        className="w-full h-full object-cover rounded-xl"
                      />
                    </motion.div>
                  </motion.div>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <section className="relative overflow-hidden bg-gradient-to-br from-gray-100 to-white min-h-[80vh]">
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-grid-black/[0.03] bg-[size:40px_40px]" />
      <div className="absolute h-full w-full bg-gradient-to-b from-white/50 to-white/20" />
      
      {/* Content Container */}
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-20 pb-16 flex flex-col lg:flex-row items-center gap-12">
        {/* Text Content */}
        <motion.div 
          className="flex-1 text-center lg:text-left space-y-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          
          <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-900 tracking-tight">
            AI-Driven Ad
            <span className="block text-white bg-gradient-to-r from-gray-400 to-black">
              Automation
            </span>
          </h1>
          
          <p className="max-w-2xl mx-auto lg:mx-0 text-lg text-gray-600">
            Transform your advertising strategy with intelligent automation. Optimize campaigns, increase ROI, and scale your business with AI-powered solutions.
          </p>
        </motion.div>

        {/* Dashboard Preview */}
        <motion.div 
          className="flex-1 relative"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="relative mx-auto max-w-[600px]">
            {/* Glow Effect */}
            <div className="absolute -inset-0.5 bg-gradient-to-r from-gray-300 to-gray-400 rounded-2xl blur opacity-30 group-hover:opacity-40 transition" />
            
            {/* Dashboard Image */}
            <div className="relative bg-white rounded-2xl border border-gray-200 shadow-xl">
              <img
                src={text}
                alt="Dashboard Preview"
                width={600}
                height={400}
                className="rounded-2xl"
              />
              
              {/* Floating Stats Card */}
              <motion.div 
                className="absolute -right-6 -top-6 bg-white backdrop-blur-lg rounded-xl p-4 border border-gray-200 shadow-xl"
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.8 }}
              >
                <div className="text-gray-900">
                  <p className="text-sm font-medium">Increase your</p>
                  <p className="text-center text-1xl font-bold">Sales</p>
                </div>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Decorative Elements */}
      <div className="absolute bottom-0 right-0 w-[500px] h-[500px] bg-gradient-radial from-gray-300/30 to-transparent blur-2xl" />
      <div className="absolute top-0 left-0 w-[500px] h-[500px] bg-gradient-radial from-gray-400/30 to-transparent blur-2xl" />
    </section>

        <section className="py-8 sm:py-12 bg-gradient-to-b from-gray-50 to-white">
          <div className="container mx-auto px-4">
            <div className="text-center">
              <h2 className="text-base font-semibold leading-7 text-blue-400">
                Core Features
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Why Choose FloNova?
              </p>
              <p className="mx-auto mt-6 max-w-xl text-center text-lg leading-8 text-gray-600">
                Our platform combines cutting-edge AI technology with intuitive design to deliver powerful marketing automation solutions.
              </p>
            </div>
            <motion.div
              variants={staggerContainer}
              initial="initial"
              whileInView="whileInView"
              viewport={{ once: true }}
              className="mt-16 grid md:grid-cols-3 gap-8"
            >
              {[
                { icon: <BarChart className="w-12 h-12 mb-4 text-blue-500" />, title: "Data-Driven Insights", description: "Leverage advanced analytics to make informed decisions and optimize your campaigns in real-time." },
                { icon: <Users className="w-12 h-12 mb-4 text-blue-500" />, title: "Audience Segmentation", description: "Target the right customers with precision using our AI-powered segmentation tools." },
                { icon: <Zap className="w-12 h-12 mb-4 text-blue-500" />, title: "Lightning-Fast Automation", description: "Streamline your workflow with intelligent automation across all marketing channels." },
                { icon: <Shield className="w-12 h-12 mb-4 text-blue-500" />, title: "Enterprise-Grade Security", description: "Rest easy knowing your data is protected with state-of-the-art security measures." },
                { icon: <Award className="w-12 h-12 mb-4 text-blue-500" />, title: "Innovative Platform", description: "Stay ahead of the curve with our cutting-edge marketing technology." },
                { icon: <Clock className="w-12 h-12 mb-4 text-blue-500" />, title: "24/7 Expert Support", description: "Our dedicated team is always available to ensure your success with FloNova." },
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  variants={popUp}
                  whileHover={{ scale: 1.05 }}
                  className="relative overflow-hidden rounded-2xl bg-white p-8 shadow-lg hover:shadow-xl transition-all"
                >
                  <div className="relative">
                    {feature.icon}
                    <h3 className="text-xl font-semibold leading-6 text-gray-900 mb-2">{feature.title}</h3>
                    <p className="text-sm leading-6 text-gray-600">{feature.description}</p>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </section>

        <section className="bg-black py-8 sm:py-12 metrics-section">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:max-w-none">
              <div className="text-center">
                <h2 className="text-base font-semibold leading-7 text-blue-400">
                  Key metrics
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-5xl">
                  Our general figures
                </p>
              </div>
              <p className="mx-auto mt-6 max-w-xl text-center text-lg leading-8 text-gray-400">
                Our services aim to create custom solutions that meet the specific needs and goals of the client, ensuring a high-quality product that is delivered on time and within budget.
              </p>
              <motion.div 
                className="mt-16 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                {metrics.map((metric, index) => (
                  <motion.div
                    key={index}
                    className={`relative overflow-hidden rounded-2xl ${
                      metric.highlight ? 'bg-purple-500/10' : 'bg-gray-900/75'
                    } p-8`}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    whileHover={{ scale: 1.02 }}
                  >
                    {metric.shape}
                    <div className="relative">
                      <dt className="text-sm font-semibold leading-6 text-gray-400">
                        {metric.label}
                      </dt>
                      <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-white">
                        {metric.value}
                      </dd>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </div>
          </div>
        </section>

    <section className="relative overflow-hidden bg-gradient-to-b from-background to-background/50 py-24">
      <div className="absolute inset-0 bg-grid-white/[0.02] bg-[size:50px_50px]" />
      
      <div className="container relative mx-auto px-4">
        <div className="grid gap-16 lg:grid-cols-2">
          {/* AI-Driven Campaign Optimization */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="space-y-8"
          >
            <div>
              <motion.h2 
                className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl"
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
              >
                AI-Driven Campaign Optimization
              </motion.h2>
              <motion.p 
                className="mt-4 text-muted-foreground"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
              >
                Harness the power of artificial intelligence to supercharge your marketing campaigns. Our AI algorithms analyze vast amounts of data to identify trends, predict customer behavior, and optimize your campaigns in real-time for maximum ROI.
              </motion.p>
            </div>

            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-primary/20 via-primary/10 to-transparent blur-3xl" />
              <div className="relative grid gap-4 sm:grid-cols-2">
                {workflowSteps.map((step, index) => {
                  const Icon = step.icon
                  return (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: step.delay }}
                    >
                      <Card className="group relative overflow-hidden border-none bg-background/60 backdrop-blur-xl">
                        <CardContent className="p-4">
                          <div className={cn("absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity", step.color)} style={{ opacity: 0.05 }} />
                          <div className={cn("mb-4 inline-flex rounded-lg p-3", step.color)}>
                            <Icon className="h-6 w-6" />
                          </div>
                          <h3 className="font-semibold">{step.title}</h3>
                          <p className="mt-2 text-sm text-muted-foreground">{step.description}</p>
                        </CardContent>
                      </Card>
                    </motion.div>
                  )
                })}
              </div>
            </div>
          </motion.div>

          {/* Personalized Customer Experiences */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="space-y-8"
          >
            <div>
              <motion.h2 
                className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl"
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
              >
                Personalized Customer Experiences
              </motion.h2>
              <motion.p 
                className="mt-4 text-muted-foreground"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
              >
                Deliver tailored marketing messages to each customer with our AI-powered personalization engine. Analyze customer data, behavior, and preferences to create hyper-targeted campaigns that resonate with your audience and drive conversions.
              </motion.p>
            </div>

            <div className="relative aspect-square w-full max-w-md mx-auto">
              <div className="absolute inset-0 bg-gradient-to-tr from-primary/20 via-primary/10 to-transparent blur-3xl" />
              <div className="relative h-full rounded-xl border bg-background/60 p-8 backdrop-blur-xl">
                {/* Central device illustration */}
                <div className="relative h-full rounded-xl border bg-background/60 p-8 backdrop-blur-xl">
                {/* Central image */}
                <motion.div
                  className="absolute inset-0 m-auto h-80 w-80 overflow-hidden rounded-3xl border bg-background shadow-lg"
                  initial={{ scale: 0.8, opacity: 0 }}
                  whileInView={{ scale: 1, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.1 }}
                >
                  <img
                    src={optimization}
                    alt="AI-powered personalization"
                    layout="fill"
                    objectFit="cover"
                    className="rounded-3xl"
                  />
                </motion.div>
                </div>
                
                {/* Orbiting touchpoints */}
                {touchpoints.map((point, index) => {
                  const Icon = point.icon
                  return (
                    <motion.div
                      key={index}
                      className={`absolute ${point.position} flex h-12 w-12 items-center justify-center rounded-full bg-background shadow-lg`}
                      initial={{ scale: 0, opacity: 0 }}
                      whileInView={{ scale: 1, opacity: 1 }}
                      viewport={{ once: true }}
                      transition={{ delay: point.delay }}
                      whileHover={{ scale: 1.1 }}
                    >
                      <Icon className="h-6 w-6 text-primary" />
                    </motion.div>
                  )
                })}

                {/* Connecting lines */}
                <svg className="absolute inset-0 h-full w-full" style={{ stroke: 'var(--primary)', strokeWidth: '1', strokeDasharray: '5 5' }}>
                  <motion.path
                    d="M 50,50 L 150,150 M 50,150 L 150,50 M 100,25 L 100,175"
                    initial={{ pathLength: 0 }}
                    whileInView={{ pathLength: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 1, delay: 0.5 }}
                  />
                </svg>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  
        

    <>
      <section id="ai-analytics" className="py-8 sm:py-12 bg-gradient-to-b from-gray-50 to-white">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <Badge className="mb-4" variant="secondary">Analytics</Badge>
            <h2 className="text-4xl font-bold tracking-tight mb-4">AI-Powered Analytics</h2>
            <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
              Unlock the power of your data with FloNova's cutting-edge AI analytics engine.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-12 items-center">
            <motion.div variants={staggerContainer} initial="initial" whileInView="whileInView" viewport={{ once: true }}>
              <motion.h3 variants={fadeInUp} className="text-2xl font-semibold mb-6">
                Unlock the Power of Your Data
              </motion.h3>
              <motion.p variants={fadeInUp} className="mb-6">
                FloNova's AI-powered analytics engine processes vast amounts of data in real-time, providing you with actionable insights to drive your marketing strategy.
              </motion.p>
              <motion.ul variants={staggerContainer} className="space-y-3 mb-6">
                {[
                  "Real-time performance tracking",
                  "Predictive analytics for campaign optimization",
                  "Custom reporting dashboards",
                  "Competitor analysis"
                ].map((item, index) => (
                  <motion.li key={index} variants={fadeInUp} className="flex items-center space-x-3">
                    <Zap className="h-5 w-5 text-primary" />
                    <span>{item}</span>
                  </motion.li>
                ))}
              </motion.ul>
              <motion.div variants={fadeInUp}>
                <Button>
                  Explore Analytics <ArrowRight className="ml-2 h-4 w-4" />
                </Button>
              </motion.div>
            </motion.div>

            <motion.div
              variants={fadeInUp}
              initial="initial"
              whileInView="whileInView"
              viewport={{ once: true }}
              className="bg-white p-6 rounded-xl shadow-lg"
            >
              <Tabs defaultValue="performance" className="w-full">
                <TabsList className="grid w-full grid-cols-2 mb-4">
                  <TabsTrigger value="performance">Performance</TabsTrigger>
                  <TabsTrigger value="prediction">Prediction</TabsTrigger>
                </TabsList>
                <TabsContent value="performance">
                  <Card>
                    <CardHeader>
                      <CardTitle>Campaign Performance</CardTitle>
                    </CardHeader>
                    <CardContent className="h-[300px]">
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={analyticsData}>
                          <Line type="monotone" dataKey="value" stroke="#3b82f6" strokeWidth={2} />
                        </LineChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </Card>
                </TabsContent>
                <TabsContent value="prediction">
                  <Card>
                    <CardHeader>
                      <CardTitle>Predicted Growth</CardTitle>
                    </CardHeader>
                    <CardContent className="h-[300px]">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart data={analyticsData}>
                          <Area type="monotone" dataKey="value" stroke="#10b981" fill="#10b981" fillOpacity={0.2} />
                        </AreaChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </Card>
                </TabsContent>
              </Tabs>
            </motion.div>
          </div>
        </div>
      </section>

      <section id="cross-platform" className="py-8 sm:py-12 bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <Badge className="mb-4" variant="secondary">Integration</Badge>
            <h2 className="text-4xl font-bold tracking-tight mb-4">Cross-Platform Campaigns</h2>
            <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
              Create, manage, and optimize campaigns across multiple platforms from a single, intuitive interface.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-12 items-center">
            <motion.div
              variants={fadeInUp}
              initial="initial"
              whileInView="whileInView"
              viewport={{ once: true }}
              className="bg-gray-100 p-6 rounded-xl shadow-lg"
            >
              <Card>
                <CardHeader>
                  <CardTitle>Platform Distribution</CardTitle>
                </CardHeader>
                <CardContent className="h-[300px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={platformData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                      >
                        {platformData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </motion.div>

            <motion.div variants={staggerContainer} initial="initial" whileInView="whileInView" viewport={{ once: true }}>
              <motion.h3 variants={fadeInUp} className="text-2xl font-semibold mb-6">
                Seamless Integration Across Channels
              </motion.h3>
              <motion.p variants={fadeInUp} className="mb-6">
                FloNova allows you to create, manage, and optimize campaigns across multiple platforms from a single, intuitive interface.
              </motion.p>
              <motion.ul variants={staggerContainer} className="space-y-3 mb-6">
                {[
                  "Unified campaign creation and management",
                  "Automated cross-platform optimization",
                  "Consistent messaging across channels",
                  "Integrated performance tracking"
                ].map((item, index) => (
                  <motion.li key={index} variants={fadeInUp} className="flex items-center space-x-3">
                    <Globe className="h-5 w-5 text-primary" />
                    <span>{item}</span>
                  </motion.li>
                ))}
              </motion.ul>
              <motion.div variants={fadeInUp}>
                <Button variant="outline">
                  Learn More <ArrowRight className="ml-2 h-4 w-4" />
                </Button>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>

      <section id="technology" className="py-8 sm:py-12 bg-gradient-to-b from-white to-gray-50">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <Badge className="mb-4" variant="secondary">Technology</Badge>
            <h2 className="text-4xl font-bold tracking-tight mb-4">Our Technology Stack</h2>
            <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
              Powered by cutting-edge AI and machine learning algorithms for unparalleled performance.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-12 items-center">
            <motion.div variants={staggerContainer} initial="initial" whileInView="whileInView" viewport={{ once: true }}>
              <motion.h3 variants={fadeInUp} className="text-2xl font-semibold mb-6">
                Cutting-Edge AI and Machine Learning
              </motion.h3>
              <motion.p variants={fadeInUp} className="mb-6">
                FloNova is built on a foundation of advanced AI and machine learning algorithms, enabling powerful predictive analytics and automated optimization.
              </motion.p>
              <motion.ul variants={staggerContainer} className="space-y-3 mb-6">
                {[
                  "Natural Language Processing for content analysis",
                  "Deep Learning for user behavior prediction",
                  "Reinforcement Learning for campaign optimization",
                  "Computer Vision for creative asset analysis"
                ].map((item, index) => (
                  <motion.li key={index} variants={fadeInUp} className="flex items-center space-x-3">
                    <Brain className="h-5 w-5 text-primary" />
                    <span>{item}</span>
                  </motion.li>
                ))}
              </motion.ul>
              <motion.div variants={fadeInUp}>
                <Button variant="secondary">
                  Explore Our Tech <Code className="ml-2 h-4 w-4" />
                </Button>
              </motion.div>
            </motion.div>

            <motion.div
              variants={fadeInUp}
              initial="initial"
              whileInView="whileInView"
              viewport={{ once: true }}
              className="bg-white p-6 rounded-xl shadow-lg"
            >
              <img
                src={techstack}
                alt="Technology Stack Visualization"
                width={500}
                height={300}
                className="w-full h-auto rounded-lg"
              />
            </motion.div>
          </div>
        </div>
      </section>
    </>

        <section id="features" className="py-8 sm:py-12 bg-gray-50">
         <PowerfulFeatures />
        </section>

        <section className="py-8 sm:py-12 bg-white">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-base font-semibold leading-7 text-blue-400">
              Get Started
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Ready to Revolutionize Your Marketing?
            </p>
            <p className="mx-auto mt-6 max-w-xl text-center text-lg leading-8 text-gray-600">
              Be among the first to experience the future of AI-powered marketing with FloNova.
            </p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
            >
              <Link
                to="/waitlist"
                className="inline-block px-8 py-3 rounded-md bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white transition-colors text-lg font-semibold"
              >
                Join the Waitlist
              </Link>
            </motion.div>
          </div>
        </section>

        <section className="py-8 sm:py-12 bg-gray-100">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-base font-semibold leading-7 text-blue-400">
              Newsletter
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Stay Updated
            </p>
            <p className="mx-auto mt-6 max-w-xl text-center text-lg leading-8 text-gray-600">
              Subscribe to our newsletter for the latest updates and early access opportunities.
            </p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
              className="max-w-md mx-auto"
            >
              <form className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="w-full px-4 py-3 rounded-md bg-white text-gray-900 font-roboto"
                />
                <button
                  type="submit"
                  className="w-full sm:w-auto px-6 py-3 rounded-md bg-blue-500 hover:bg-blue-600 text-white transition-colors font-semibold"
                >
                  Subscribe
                </button>
              </form>
            </motion.div>
          </div>
        </section>
      </main>

      {/* Add the FAQ section before the footer */}
      <FAQSection />

      <footer className="bg-gray-100 py-6 md:py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-col items-center space-y-4 md:flex-row md:justify-between md:items-center md:space-y-0">
            <p className="text-sm text-center md:text-left font-inter">© Copyright 2024. All Rights Reserved by ZVortex</p>
            <nav className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
              <Link to="/contact" className="text-sm hover:text-blue-600 transition-colors font-roboto">Contact</Link>
              <a 
                href="https://ragtagequation.github.io/Zvortex_T-C/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-sm hover:text-blue-600 transition-colors font-roboto"
              >
                Terms & Conditions
              </a>
              <a 
                href="https://ragtagequation.github.io/Zvortex_PP/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-sm hover:text-blue-600 transition-colors font-roboto"
              >
                Privacy Policy
              </a>
            </nav>
          </div>
        </div>
      </footer>
    </div>
  )
}