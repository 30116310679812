import React from 'react';
import { motion } from 'framer-motion';
import { Code, Database, Globe, Lock, Zap, Cloud } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function APIPage() {
  const apis = [
    {
      name: "Authentication API",
      description: "Secure user authentication and authorization endpoints with JWT support.",
      icon: <Lock className="w-8 h-8" />,
      endpoints: ["POST /auth/login", "POST /auth/register", "GET /auth/verify"],
      color: "from-blue-500/10 to-purple-500/10"
    },
    {
      name: "Analytics API",
      description: "Real-time analytics and data processing endpoints for campaign tracking.",
      icon: <Database className="w-8 h-8" />,
      endpoints: ["GET /analytics/metrics", "POST /analytics/events", "GET /analytics/reports"],
      color: "from-purple-500/10 to-pink-500/10"
    },
    {
      name: "Campaign API",
      description: "Comprehensive campaign management and optimization endpoints.",
      icon: <Globe className="w-8 h-8" />,
      endpoints: ["POST /campaigns/create", "PUT /campaigns/update", "GET /campaigns/stats"],
      color: "from-pink-500/10 to-blue-500/10"
    },
    {
      name: "Integration API",
      description: "Connect and sync with popular marketing platforms and tools.",
      icon: <Cloud className="w-8 h-8" />,
      endpoints: ["POST /integrate/connect", "GET /integrate/status", "DELETE /integrate/disconnect"],
      color: "from-blue-500/10 to-green-500/10"
    }
  ];

  return (
    <div className="min-h-screen bg-[#000000] overflow-hidden font-['Syne']">
      {/* Background elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-pink-500/5 animate-gradient-x opacity-30" />
        <div 
          className="absolute inset-0 opacity-[0.03]"
          style={{
            backgroundImage: `radial-gradient(circle at center, white 0.5px, transparent 0.5px)`,
            backgroundSize: '24px 24px'
          }}
        />
      </div>

      <div className="container mx-auto px-4 py-24 relative z-10">
        {/* Header */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-20"
        >
          <h1 className="font-['ClashDisplay'] text-6xl font-light text-white tracking-tighter mb-6">
            API Documentation
          </h1>
          <p className="text-gray-400 text-xl font-light tracking-wider max-w-2xl mx-auto">
            Integrate FloNova's powerful AI marketing capabilities into your applications with our comprehensive API suite.
          </p>
        </motion.div>

        {/* API Grid */}
        <div className="grid md:grid-cols-2 gap-8">
          {apis.map((api, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`bg-gradient-to-br ${api.color} backdrop-blur-sm rounded-2xl p-8 border border-gray-800/30`}
            >
              <div className="flex items-center gap-4 mb-6">
                <div className="text-white">
                  {api.icon}
                </div>
                <h2 className="font-['ClashDisplay'] text-2xl font-light text-white tracking-tighter">
                  {api.name}
                </h2>
              </div>
              
              <p className="text-gray-400 mb-8 text-sm tracking-wider">
                {api.description}
              </p>

              <div className="space-y-3">
                {api.endpoints.map((endpoint, i) => (
                  <div 
                    key={i}
                    className="bg-[#1A1A1A] px-4 py-3 rounded-none font-mono text-sm text-white/70"
                  >
                    {endpoint}
                  </div>
                ))}
              </div>

              <Link
                to={`/apis/${api.name.toLowerCase().replace(/\s+/g, '-')}`}
                className="mt-6 w-full px-4 py-3 bg-blue-500 hover:bg-blue-600 text-white text-[13px] transition-colors border-0 tracking-wider inline-block text-center"
              >
                View Documentation
              </Link>
            </motion.div>
          ))}
        </div>

        {/* Integration Steps */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="mt-20 text-center"
        >
          <h2 className="font-['ClashDisplay'] text-3xl font-light text-white tracking-tighter mb-12">
            Getting Started
          </h2>
          
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <Code className="w-6 h-6" />,
                title: "Get API Key",
                description: "Sign up and obtain your API credentials from the dashboard."
              },
              {
                icon: <Zap className="w-6 h-6" />,
                title: "Quick Integration",
                description: "Follow our simple integration guide to connect your application."
              },
              {
                icon: <Globe className="w-6 h-6" />,
                title: "Go Live",
                description: "Test your integration and deploy to production."
              }
            ].map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.7 + (index * 0.1) }}
                className="bg-[#111111]/50 backdrop-blur-sm rounded-2xl p-6 border border-gray-800/30"
              >
                <div className="text-blue-400 mb-4">
                  {step.icon}
                </div>
                <h3 className="font-['ClashDisplay'] text-xl font-light text-white mb-2">
                  {step.title}
                </h3>
                <p className="text-gray-400 text-sm">
                  {step.description}
                </p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
} 