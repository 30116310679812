import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage';
import DemoRequestPage from './DemoRequestPage';
import ThankYouPage from './ThankYouPage';
import WaitlistPage from './WaitlistPage';
import ThankYouWaitlistPage from './ThankYouWaitlistPage';
import ContactPage from './ContactPage';
import ThankYouContactPage from './ThankYouContactPage';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import APIPage from './APIPage';
import APIDocumentation from './components/APIDocumentation';
import { Lock, Database, Globe, Cloud } from 'lucide-react';
import './globals.css'
import x from './X.js'
import TeamPage from './TeamPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/demo-request" element={<DemoRequestPage />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/waitlist" element={<WaitlistPage />} />
        <Route path="/thank-you-waitlist" element={<ThankYouWaitlistPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/thank-you-contact" element={<ThankYouContactPage />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/apis" element={<APIPage />} />
        <Route 
          path="/apis/authentication-api" 
          element={
            <APIDocumentation 
              api={{
                name: "Authentication API",
                description: "Secure user authentication and authorization endpoints with JWT support.",
                icon: <Lock className="w-8 h-8" />,
                endpoints: ["POST /auth/login", "POST /auth/register", "GET /auth/verify"]
              }}
            />
          }
        />
        <Route 
          path="/apis/analytics-api" 
          element={
            <APIDocumentation 
              api={{
                name: "Analytics API",
                description: "Real-time analytics and data processing endpoints for campaign tracking.",
                icon: <Database className="w-8 h-8" />,
                endpoints: ["GET /analytics/metrics", "POST /analytics/events", "GET /analytics/reports"]
              }}
            />
          }
        />
        <Route 
          path="/apis/campaign-api" 
          element={
            <APIDocumentation 
              api={{
                name: "Campaign API",
                description: "Comprehensive campaign management and optimization endpoints.",
                icon: <Globe className="w-8 h-8" />,
                endpoints: ["POST /campaigns/create", "PUT /campaigns/update", "GET /campaigns/stats"]
              }}
            />
          }
        />
        <Route 
          path="/apis/integration-api" 
          element={
            <APIDocumentation 
              api={{
                name: "Integration API",
                description: "Connect and sync with popular marketing platforms and tools.",
                icon: <Cloud className="w-8 h-8" />,
                endpoints: ["POST /integrate/connect", "GET /integrate/status", "DELETE /integrate/disconnect"]
              }}
            />
          }
        />
        <Route path="/team" element={<TeamPage />} />
      </Routes>
    </Router>
  );
}

export default App;
