import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function ThankYouPage() {
  return (
    <div className="min-h-screen bg-[#000000] flex items-center justify-center relative overflow-hidden font-['Syne']">
      {/* Background elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-pink-500/5 animate-gradient-x opacity-30" />
        <div 
          className="absolute inset-0 opacity-[0.03]"
          style={{
            backgroundImage: `radial-gradient(circle at center, white 0.5px, transparent 0.5px)`,
            backgroundSize: '24px 24px'
          }}
        />
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-md w-full bg-[#111111]/50 backdrop-blur-sm rounded-2xl p-8 border border-gray-800/30 mx-4 relative z-10"
      >
        <h2 className="font-['ClashDisplay'] text-3xl font-light text-white mb-6 tracking-tighter">
          Thank You!
        </h2>
        <p className="text-gray-400 mb-8 text-sm tracking-wider">
          We've received your demo request and will contact you shortly to schedule your personalized demo.
        </p>
        <Link
          to="/"
          className="inline-block w-full px-8 py-3 bg-blue-500 hover:bg-blue-600 text-white text-[13px] transition-colors border-0 tracking-wider h-[48px] text-center leading-[30px]"
        >
          Back to Home
        </Link>
      </motion.div>
    </div>
  );
}
