import React from 'react';
import { motion } from 'framer-motion';
import { Code, Copy, Check } from 'lucide-react';
import { useState } from 'react';

export default function APIDocumentation({ api }) {
  const [copiedEndpoint, setCopiedEndpoint] = useState(null);

  const copyToClipboard = (text, index) => {
    navigator.clipboard.writeText(text);
    setCopiedEndpoint(index);
    setTimeout(() => setCopiedEndpoint(null), 2000);
  };

  const codeExamples = {
    "Authentication API": {
      login: `
fetch('https://api.flonova.ai/auth/login', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    email: 'user@example.com',
    password: 'secure_password'
  })
})`,
      register: `
fetch('https://api.flonova.ai/auth/register', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    email: 'user@example.com',
    password: 'secure_password',
    name: 'John Doe'
  })
})`
    },
    "Analytics API": {
      metrics: `
fetch('https://api.flonova.ai/analytics/metrics', {
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY'
  }
})`,
      events: `
fetch('https://api.flonova.ai/analytics/events', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    event_type: 'page_view',
    properties: {
      page: '/home'
    }
  })
})`
    },
    "Campaign API": {
      create: `
fetch('https://api.flonova.ai/campaigns/create', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    name: 'Summer Campaign 2024',
    target_audience: ['age_25_34', 'interests_tech'],
    budget: 5000
  })
})`
    },
    "Integration API": {
      connect: `
fetch('https://api.flonova.ai/integrate/connect', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    platform: 'facebook',
    credentials: {
      access_token: 'PLATFORM_ACCESS_TOKEN'
    }
  })
})`
    }
  };

  return (
    <div className="min-h-screen bg-[#000000] overflow-hidden font-['Syne'] py-24">
      {/* Background elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-pink-500/5 animate-gradient-x opacity-30" />
        <div 
          className="absolute inset-0 opacity-[0.03]"
          style={{
            backgroundImage: `radial-gradient(circle at center, white 0.5px, transparent 0.5px)`,
            backgroundSize: '24px 24px'
          }}
        />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-4xl mx-auto"
        >
          <div className="flex items-center gap-4 mb-8">
            <div className="text-white">
              {api.icon}
            </div>
            <h1 className="font-['ClashDisplay'] text-4xl font-light text-white tracking-tighter">
              {api.name}
            </h1>
          </div>

          <div className="prose prose-invert max-w-none">
            <p className="text-gray-400 text-lg mb-12">
              {api.description}
            </p>

            <div className="space-y-12">
              {api.endpoints.map((endpoint, index) => (
                <div key={index} className="bg-[#111111]/50 backdrop-blur-sm rounded-2xl p-8 border border-gray-800/30">
                  <div className="flex justify-between items-center mb-4">
                    <code className="text-blue-400 text-lg">{endpoint}</code>
                    <button
                      onClick={() => copyToClipboard(endpoint, index)}
                      className="text-gray-400 hover:text-white transition-colors"
                    >
                      {copiedEndpoint === index ? (
                        <Check className="w-4 h-4" />
                      ) : (
                        <Copy className="w-4 h-4" />
                      )}
                    </button>
                  </div>

                  <div className="bg-[#1A1A1A] p-6 rounded-lg overflow-x-auto">
                    <pre className="text-gray-300 font-mono text-sm">
                      <code>{codeExamples[api.name][endpoint.split('/').pop()]}</code>
                    </pre>
                  </div>

                  <div className="mt-6">
                    <h3 className="text-white text-lg mb-2">Response</h3>
                    <div className="bg-[#1A1A1A] p-6 rounded-lg">
                      <pre className="text-gray-300 font-mono text-sm">
                        <code>{`{
  "status": "success",
  "data": {
    // Response data will appear here
  }
}`}</code>
                      </pre>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
} 