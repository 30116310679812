import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

export default function ContactPage() {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/thank-you-contact');
  };

  return (
    <section className="relative min-h-screen bg-[#000000] overflow-hidden font-['Syne'] flex items-center">
      {/* Background elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-pink-500/5 animate-gradient-x opacity-30" />
        <div 
          className="absolute inset-0 opacity-[0.03]"
          style={{
            backgroundImage: `radial-gradient(circle at center, white 0.5px, transparent 0.5px)`,
            backgroundSize: '24px 24px'
          }}
        />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-7xl mx-auto flex justify-between items-center gap-20">
          {/* Left Side Content */}
          <div className="flex-1">
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="font-['ClashDisplay'] text-8xl font-light text-white tracking-tighter leading-[0.9]"
            >
              Get in Touch
              <span className="block text-7xl mt-4 bg-gradient-to-r from-white via-gray-200 to-white bg-clip-text text-transparent">
                with Us
              </span>
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-gray-500 text-xl font-light tracking-wider mt-auto absolute bottom-0 mb-16"
            >
              The more we know, the better we can help.
            </motion.p>
          </div>

          {/* Contact Form Card */}
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="w-[600px] bg-[#111111]/50 backdrop-blur-sm rounded-2xl p-8 border border-gray-800/30"
          >
            <div className="mb-8 flex justify-between items-center">
              <span className="text-white/80 text-sm tracking-wider">Contact Information</span>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <input
                  type="text"
                  placeholder="YOUR NAME HERE"
                  required
                  className="w-full bg-[#1A1A1A] border-0 rounded-none px-4 py-3 text-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-500/50 transition-all text-[13px] tracking-wider h-[48px]"
                />
              </div>
              <div>
                <input
                  type="email"
                  placeholder="EMAIL"
                  required
                  className="w-full bg-[#1A1A1A] border-0 rounded-none px-4 py-3 text-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-500/50 transition-all text-[13px] tracking-wider h-[48px]"
                />
              </div>
              <div>
                <input
                  type="tel"
                  placeholder="PHONE NUMBER"
                  required
                  className="w-full bg-[#1A1A1A] border-0 rounded-none px-4 py-3 text-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-500/50 transition-all text-[13px] tracking-wider h-[48px]"
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="COMPANY NAME"
                  required
                  className="w-full bg-[#1A1A1A] border-0 rounded-none px-4 py-3 text-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-500/50 transition-all text-[13px] tracking-wider h-[48px]"
                />
              </div>

              <div className="pt-6">
                <button 
                  type="submit"
                  className="w-full px-8 py-3 rounded-none bg-blue-500 hover:bg-blue-600 text-white text-[13px] transition-colors border-0 tracking-wider h-[48px]"
                >
                  Submit
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      </div>
    </section>
  );
} 